// Npm
import { put } from 'redux-saga/effects';
import Axios from 'appUtils/axios';
import map from 'lodash/fp/map';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';

// App
import Base from './api';
import { notification } from './notification';

export default class Board extends Base {
  *getDetail(action) {
    const url = `${this.URL}${action.id}/`;
    try {
      // Call using the request helper
      const resp = yield Axios.get(url);
      yield put(this.getDetailSuccess(resp.data));

      if (resp.data) {
        const _segments = flow(map('video_segment'), flatten)(resp.data.board_video || []);

        const list = [..._segments, ...(resp.data.segments || []), ...(resp.data.gifList || [])];

        if (list.length !== 0) {
          const library = new Base('library', '/api/v1/source/library/');
          yield put(library.getSuccess(list));
        }
      }
    } catch (err) {
      yield put(this.getDetailError(err));
      yield put(notification(`${this.APP_NAME} get detail error ${err.message}`, 'error'));
    }
  }
}
