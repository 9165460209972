import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FontFaceObserver from 'fontfaceobserver';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import { SnackbarProvider } from 'notistack';

// Import root app
import App from 'containers/Public/index';
import GlobalProvider from 'context/GlobalContext';

import 'sanitize.css/sanitize.css';
import 'styles/theme.scss';
import './tailwind.css';

import store from 'store/configure';
import ContextProvider from './ContextProvider';

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(
  () => {
    document.body.classList.add('fontLoaded');
  },
  () => {
    document.body.classList.remove('fontLoaded');
  },
);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: blue[500],
    },
  },
  typography: {},

  overrides: {
    MuiGrid: {
      item: {},
    },

    MuiFab: {
      root: {
        textTransform: 'capitalize',
      },
    },

    MuiButton: {
      label: {
        textTransform: 'capitalize',
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider>
            <ContextProvider store={store}>
              <GlobalProvider>
                <App />
              </GlobalProvider>
            </ContextProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </HashRouter>
    </Provider>
  </React.StrictMode>,
);
