/*
 * GIF
 *
 * This is the first thing users see of our App, at the '/' route
 */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import sample from 'lodash.sample';
import { Img } from 'react-image';
import { throttle } from 'throttle-debounce';
import { AutoComplete } from 'antd';
import { Link } from 'react-router-dom';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LazyLoad from 'react-lazyload';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import red from '@material-ui/core/colors/red';
import indigo from '@material-ui/core/colors/indigo';

import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

// App
import { segment as segmentApi, label as labelApi } from 'appStore/index';
import './style.scss';

const COLORS = ['#96ceb4', '#ffeead', '#ff6f69', '#ffcc5c', '#88d8b0'];

const CARD_MARGIN = 0;

const getStartTime = startTime => {
  if (!startTime) return '';
  const st = startTime.split(':');
  return `&t=${Number(st[0] || 0) * 60 + Number(st[1] || 0)}`;
};

const PlaceholderComponent = () => (
  <div
    style={{ width: '100%', height: '100%', backgroundColor: sample(COLORS), position: 'absolute' }}
  />
);

const imageStyles = theme => ({
  chip: {
    margin: theme.spacing(1),
    marginTop: 5,
    marginBottom: 0,
  },

  leftIcon: {
    marginRight: theme.spacing(1),
  },
  progressLoader: {},

  favourite: {
    float: 'right',
    padding: 10,
    margin: 0,
    color: indigo[500],
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  textRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  input: {
    marginLeft: 8,
    flex: 1,
  },

  iconButton: {
    padding: 10,
  },

  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

class InternalImageComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      edit: false,
      name: props.segment && props.segment.name,
      autocomplete: [],
    };

    this.searchAutoComplete = throttle(100, this._searchAutoComplete);
  }

  _searchAutoComplete = name => {
    if (!name) {
      this.setState({ autocomplete: [] });
    } else {
      const moves = this.props.labelList.filter(
        x => x.toLowerCase().indexOf(name.toLowerCase()) !== -1,
      );
      this.setState({ autocomplete: moves.length > 10 ? moves.slice(0, 10) : moves });
    }
  };

  renderAutoComplete() {
    return (
      <AutoComplete
        value={this.state.name}
        type="text"
        size="large"
        dataSource={this.state.autocomplete}
        style={{ width: '80%' }}
        onChange={name => {
          this.setState({ name });
          this.searchAutoComplete(name);
        }}
        onSelect={name => {
          this.setState({ name });
          this.searchAutoComplete(name);
        }}
      />
    );
  }
  renderMoveName() {
    const { classes = {}, segment, patchSegment } = this.props;

    if (!this.state.edit) return null;
    return (
      <Paper className={classes.textRoot} elevation={0}>
        {this.renderAutoComplete()}

        <Divider className={classes.divider} />

        <IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="Directions"
          onClick={() => {
            patchSegment({ id: segment.id, name: this.state.name });
            this.setState({ edit: false });
          }}
        >
          <SaveIcon />
        </IconButton>

        <Divider className={classes.divider} />

        <IconButton
          aria-label="Cancel edit"
          className={classes.favourite}
          onClick={() => this.setState({ edit: false, name: segment.name })}
        >
          <CancelIcon style={{ fill: red[500] }} />
        </IconButton>
      </Paper>
    );
  }

  renderSubHeader() {
    const { segment, classes = {} } = this.props;
    return (
      <Fragment>
        <Link to={segment.dance ? `/label/${segment.dance}` : ''}>
          <Chip
            label={this.state.name || segment.name || '-'}
            className={classes.chip}
            color="primary"
          />
        </Link>

        <Link to={`/segment/${segment.id}`}>
          <Chip label={segment.id} className={classes.chip} variant="outlined" color="primary" />
        </Link>

        <Chip
          label={`${Number(segment.duration).toFixed(2)}s`}
          className={classes.chip}
          variant="outlined"
        />

        {segment.migrated && <Chip label="migrated" className={classes.chip} variant="outlined" />}
      </Fragment>
    );
  }

  render() {
    const { classes = {}, segment, style } = this.props;
    if (!(segment.gif || segment.optimized_gif)) return '';
    const loaderSize =
      segment.height - CARD_MARGIN < 200 ? (segment.height - CARD_MARGIN) * 0.5 : 100;

    return (
      <Card key={segment.id} style={style}>
        <CardHeader style={{ padding: 8 }} title="" subheader={this.renderSubHeader()} />

        <CardContent
          className={classes.content}
          style={{ padding: 0, height: segment.height + CARD_MARGIN }}
        >
          <div
            style={{ height: segment.height - CARD_MARGIN, position: 'relative', display: 'flex' }}
          >
            <LazyLoad
              placeholder={<PlaceholderComponent />}
              height={segment.height - CARD_MARGIN}
              resize
            >
              <Img
                src={[
                  segment.optimized_gif || segment.gif, // first scenario is if no optimized
                  segment.gif, // default in iphone
                ]}
                style={{
                  width: `${style.width}px`,
                  height: `${segment.height - CARD_MARGIN}px`,
                }}
                loader={
                  <div
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#e1e1e1',
                      height: segment.height - CARD_MARGIN,
                      width: '100%',
                    }}
                  >
                    <CircularProgress
                      className={classes.progressLoader}
                      size={loaderSize}
                      style={{
                        marginTop: (segment.height - CARD_MARGIN) / 3,
                      }}
                    />
                  </div>
                }
              />
            </LazyLoad>
          </div>

          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 0 }}>
              {this.renderMoveName()}
            </Grid>
          </Grid>
        </CardContent>

        {!this.state.edit && (
          <CardActions>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => this.props.getSegment(`${segment.id}/migrate`)}
            >
              Migrate
            </Button>

            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ marginRight: 20 }}
              onClick={() => this.setState({ edit: !this.state.edit })}
            >
              Edit
            </Button>
          </CardActions>
        )}
      </Card>
    );
  }
}

const mapDispatchToPropsImageComponent = dispatch => ({
  patchSegment: data => dispatch(segmentApi.patchRequest(data)),
  getSegment: id => dispatch(segmentApi.getDetailRequest(id)),
});

const mapStateToPropsImageComponent = createStructuredSelector({
  currentSegment: segmentApi.makeSelectBase('detail'),
  labelList: labelApi.makeSelectBase('list'),
});

export default connect(
  mapStateToPropsImageComponent,
  mapDispatchToPropsImageComponent,
)(withStyles(imageStyles, { withTheme: true })(InternalImageComponent));
