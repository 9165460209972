export const MOBILE_BREAKPOINT = 420;

export const breakpoints = {
  isMobile: (width: number) => width < 420,

  getColumnWidth: (width: number) => {
    switch (!!width) {
      case width <= 360: {
        return 360;
      }
      default: {
        return 400;
      }
    }
  },
};

// helper functions
export const findAndReplace = (array: any, find: any, replace: any) => {
  const arr = [...array];
  let i;
  for (i = 0; i < arr.length && arr[i].id !== find.id; i++) {}
  if (i < arr.length) arr[i] = replace;
  else arr.push(replace);
  return arr;
};

export const encodeUrl = (data: any) =>
  Object.keys(data)
    .map(function (k) {
      if (Array.isArray(data[k])) {
        return data[k]
          .map((v: string) => encodeURIComponent(k) + '=' + encodeURIComponent(v))
          .join('&');
      }
      return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
    })
    .join('&');

export function generateErrorDict(err: any) {
  return {
    name: err.name,
    message: err.message,
    lineNumber: err.lineNumber,
    columnNumber: err.columnNumber,
    fileName: err.fileName,
    description: err.description,
  };
}

export const notifyDict = (success: string, error: string) => {
  return { notify: { success, error } };
};

export const Capitalize = (str: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : str;

// https://stackoverflow.com/questions/3733227/javascript-seconds-to-minutes-and-seconds
export const fancyTimeFormat = (_time: string): string => {
  // Hours, minutes and seconds
  const time = Number(_time);
  const hrs = ~~(time / 3600);
  const mins = ~~((time % 3600) / 60);
  const secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;
  return ret;
};
