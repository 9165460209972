import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import sample from 'lodash.sample';
import Img from 'react-image';
import LazyLoad from 'react-lazyload';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import red from '@material-ui/core/colors/red';

import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

// App
import { board, segment as segmentApi } from 'appStore/index';
import { fancyTimeFormat } from 'appUtils/core';
import './style.scss';

const COLORS = ['#96ceb4', '#ffeead', '#ff6f69', '#ffcc5c', '#88d8b0'];

const CARD_MARGIN = 50;

const PlaceholderComponent = () => (
  <div
    style={{ width: '100%', height: '100%', backgroundColor: sample(COLORS), position: 'absolute' }}
  />
);

const imageStyles = theme => ({
  chip: {
    margin: theme.spacing(1),
    marginTop: 5,
    marginBottom: 0,
  },

  leftIcon: {
    marginRight: theme.spacing(1),
  },
  progressLoader: {},

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  textRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  input: {
    marginLeft: 8,
    flex: 1,
  },

  iconButton: {
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },

  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

class SharedImageComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      edit: false,
      name: props.segment && props.segment.name,
    };
  }

  renderMoveName() {
    const { classes = {}, segment, currentBoard, patchSegment } = this.props;
    if (currentBoard.can_shared_edit && this.state.edit) {
      return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className={classes.textRoot} elevation={0}>
            <TextField
              id="move-name"
              label="Move Name"
              type="text"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={this.state.name}
              onChange={ev => this.setState({ name: ev.target.value })}
              fullWidth
            />

            <Divider className={classes.divider} />

            <IconButton
              color="primary"
              className={classes.iconButton}
              aria-label="Directions"
              onClick={() => {
                patchSegment({ id: segment.id, name: this.state.name, token: currentBoard.id });
                this.setState({ edit: false });
              }}
            >
              <SaveIcon />
            </IconButton>

            <Divider className={classes.divider} />

            <IconButton
              aria-label="Cancel edit"
              className={classes.favourite}
              onClick={() => this.setState({ edit: false, name: segment.name })}
            >
              <CancelIcon style={{ fill: red[500] }} />
            </IconButton>
          </Paper>
        </Grid>
      );
    }

    return (
      <Grid item xs={8} sm={8} md={10} lg={10}>
        <Typography
          variant="h6"
          gutterBottom
          style={{ textTransform: 'capitalize', marginTop: 10 }}
        >
          <Button color="primary" className={classes.button} style={{ fontWeight: 'bolder' }}>
            {segment.name}
          </Button>
        </Typography>
      </Grid>
    );
  }

  renderEditButton() {
    const { currentBoard, classes } = this.props;
    if (currentBoard.can_shared_edit && !this.state.edit) {
      return (
        <Grid item xs={4} sm={4} md={2} lg={2}>
          <IconButton
            aria-label="Edit Icon"
            className={classes.favourite}
            onClick={() => this.setState({ edit: true })}
          >
            <EditIcon />
          </IconButton>
        </Grid>
      );
    }
  }

  renderTime() {
    const { currentBoard, segment, classes } = this.props;
    if (currentBoard.can_shared_edit && this.state.edit) return '';
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Typography variant="h6" gutterBottom style={{ textTransform: 'capitalize' }}>
          <Chip
            label={`${fancyTimeFormat(segment.start)} - ${fancyTimeFormat(
              Number(segment.start) + Number(segment.duration),
            )}`}
            className={classes.chip}
            variant="outlined"
          />
        </Typography>
      </Grid>
    );
  }

  render() {
    const { classes = {}, segment, style, gifSetGetParams, getGIFs, gifParams, reset } = this.props;
    if (!(segment.gif || segment.optimized_gif)) return '';
    const loaderSize =
      segment.height - CARD_MARGIN < 200 ? (segment.height - CARD_MARGIN) * 0.5 : 100;

    return (
      <Card key={segment.id} style={style}>
        <CardContent
          className={classes.content}
          style={{ padding: 0, height: segment.height + CARD_MARGIN }}
        >
          <div
            style={{ height: segment.height - CARD_MARGIN, position: 'relative', display: 'flex' }}
          >
            <LazyLoad
              placeholder={<PlaceholderComponent />}
              height={segment.height - CARD_MARGIN}
              resize
            >
              <Img
                src={[
                  segment.optimized_gif || segment.gif, // first scenario is if no optimized
                  segment.gif, // default in iphone
                ]}
                style={{
                  width: `${style.width}px`,
                  height: `${segment.height - CARD_MARGIN}px`,
                }}
                loader={
                  <div
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#e1e1e1',
                      height: segment.height - CARD_MARGIN,
                      width: '100%',
                    }}
                  >
                    <CircularProgress
                      className={classes.progressLoader}
                      size={loaderSize}
                      style={{
                        marginTop: (segment.height - CARD_MARGIN) / 3,
                      }}
                    />
                  </div>
                }
              />
            </LazyLoad>
          </div>

          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 0 }}>
              <Grid container spacing={24}>
                {this.renderMoveName()}

                {this.renderEditButton()}
              </Grid>
            </Grid>
          </Grid>

          {this.renderTime()}
        </CardContent>
      </Card>
    );
  }
}

const mapDispatchToPropsImageComponent = dispatch => ({
  patchSegment: data => dispatch(segmentApi.patchRequest(data)),
});

const mapStateToPropsImageComponent = createStructuredSelector({
  currentBoard: board.makeSelectBase('detail'),
});

export default connect(
  mapStateToPropsImageComponent,
  mapDispatchToPropsImageComponent,
)(withStyles(imageStyles, { withTheme: true })(SharedImageComponent));
