/*
 * GIF
 *
 * This is the first thing users see of our App, at the '/' route
 */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import classnames from 'classnames';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';

// App
import CustomButton from 'components/mik/CustomButtons/Button.js';
import { board as boardApi } from 'appStore/index';

import './style.scss';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: grey[500],
  },
}));

export default function RenderFolderDialog({ handleClose, header }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [name, setName] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const boardList = useSelector(state => state[boardApi.APP_NAME].list);
  const currentBoard = useSelector(state => state[boardApi.APP_NAME].detail);

  useEffect(() => {
    dispatch(boardApi.getRequest());
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="scroll-dialog-title">
        {header}
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent style={{ minHeight: 300 }}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem>
            <Grid container spacing={3} style={{ marginBottom: 30 }}>
              <Grid item xs={10}>
                <TextField
                  id="outlined-basic"
                  label="Add List"
                  variant="outlined"
                  value={name}
                  fullWidth
                  onChange={ev => setName(ev.target.value)}
                />
              </Grid>

              <Grid item xs={2}>
                <CustomButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    if (name) {
                      dispatch(boardApi.postRequest({ name })).then(resp => {
                        if (resp.id) {
                          dispatch(boardApi.getDetailRequest(resp.id));
                        }
                      });
                    }
                  }}
                >
                  Add
                </CustomButton>
              </Grid>
            </Grid>
          </ListItem>

          {boardList.map(b => (
            <div key={b.id}>
              <ListItem
                button
                onClick={() => {
                  dispatch(boardApi.getDetailRequest(b.id));
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconButton
                    aria-label="Folder"
                    className={classnames(classes.margin, 'tag-scroll')}
                    style={{ margin: 0, padding: 0, marginRight: 16 }}
                  >
                    <FontAwesomeIcon
                      icon={currentBoard.id === b.id ? faFolderOpen : faFolder}
                      style={{
                        fontSize: 34,
                        marginTop: 4,
                        color: currentBoard.id === b.id ? blue[500] : grey[200],
                      }}
                    />
                  </IconButton>
                </ListItemIcon>

                <ListItemText primary={`${b.name} - (${b.favourite_count} moves)`} />
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
