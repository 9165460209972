import { Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import Measure from 'react-measure';
import classnames from 'classnames';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// App
import Dance from 'containers/Dance/index';
import Favourite from 'containers/Favourite/index';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Header from 'components/Header';
import { onChange as appOnChange } from 'store/app';
import Notification from 'components/Notification';
import 'styles/dashboard.scss';
import Partner from 'containers/Partner/index';

const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    top: '0',
    height: '100vh',
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    minWidth: 0, // So the Typography noWrap works

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3),
    },
  },
  mainPanel: {
    position: 'relative',
    ...transition,
    maxHeight: '100%',
    width: '100%',
  },
}));

const Loading = () => (
  <Grid container justifyContent="center" style={{ marginBottom: 25 }}>
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <CircularProgress style={{ marginTop: 75 }} />
    </Grid>
  </Grid>
);

export default function App() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const getDimensions = (dimensions: any) => dispatch(appOnChange('dimensions', dimensions));

  return (
    <>
      <Header />

      {/* @ts-ignore */}
      <Measure
        bounds
        onResize={contentRect => {
          getDimensions(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef} className={classes.root}>
            <div className={classnames(classes.mainPanel)}>
              <div className={classes.content} style={{ marginTop: '0px', minHeight: '100vh' }}>
                <div>
                  <Suspense fallback={<Loading />}>
                    <Routes>
                      {/* @ts-ignore */}
                      <Route path="/" element={<Dance />} />
                      {/* @ts-ignore */}
                      <Route path="/label/:tag" element={<Dance />} />
                      {/* @ts-ignore */}
                      <Route path="/saved/" element={<Favourite />} />
                      <Route path="/partners" element={<Partner />} />
                      <Route path="" element={<NotFoundPage />} />
                    </Routes>
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        )}
      </Measure>

      <Notification />
    </>
  );
}
