import { combineReducers, configureStore } from '@reduxjs/toolkit';

// App
import rootReducer from './index';

const reducer = combineReducers({
  ...rootReducer,
});

const store = configureStore({
  reducer,
  preloadedState: { app: { dimensions: {} } }, // Initial state
  devTools: process.env.NODE_ENV !== 'production',
});

// // Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
