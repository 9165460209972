import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { blue, grey } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';

// App
import MainComponent from './Main';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: grey[500],
  },
  backButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: grey[500],
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    marginTop: 25,
    // width: 400
  },

  authIconButton: {},
}));

export function AuthDialog({ onClose, open, onSuccess }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isEmail, setIsEmail] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="scroll-dialog-title">
        {isEmail ? (
          <IconButton
            aria-label="back"
            className={classes.backButton}
            onClick={() => {
              setIsEmail(false);
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        ) : null}

        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>
        <MainComponent
          onClose={onClose}
          onSuccess={onSuccess}
          setIsEmail={setIsEmail}
          setIsLogin={setIsLogin}
          isLogin={isLogin}
          isEmail={isEmail}
        />
      </DialogContent>
    </Dialog>
  );
}

AuthDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function AuthModal({ title, onSuccess }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          handleClickOpen();
        }}
        fontSize={12}
      >
        {title}
      </Button>
      <AuthDialog open={open} onClose={handleClose} onSuccess={onSuccess} />
    </>
  );
}
