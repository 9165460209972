import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import { BackTop } from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

// App
import { likeMove as likeMoveApi, board as boardApi, gif as gifApi } from 'store/index';
import MasonryComponent from 'components/Masonry';
import RenderFolderDialog from 'components/Board/FavouriteModal';
import { useSelector, useDispatch, useGlobal } from 'hooks';
import { GifType, SavedGifType } from 'types/api';
import './style.scss';

const CARD_MARGIN = 110;
const LAST_CALL = {
  LOAD_MORE: 'LOAD_MORE',
  GET_FILTERED: 'GET_FILTERED',
  SEARCH: 'SEARCH',
};

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  progress: {
    margin: theme.spacing(2),
  },
  tagProgress: {
    margin: '4px',
  },
  textCenter: {
    textAlign: 'center',
  },
  iconPaperRoot: {
    width: '100%',
    padding: '10px 20px',
    zIndex: 5,
    display: 'flex',
  },
  paddingRoot: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 20px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0px 40px',
    },
  },
}));

const loadMore = debounce(500, ({ loading, url, lastFetch, params, dispatch }) => {
  if (
    !loading && // Must not be loading
    url && // The next URL must be set
    lastFetch &&
    Math.floor(Date.now() / 1000) - lastFetch > 2 // Giving some time to populate the DOM
  ) {
    dispatch(likeMoveApi.setGetParams({ ...params, lastCall: LAST_CALL.LOAD_MORE }));
    dispatch(likeMoveApi.getRequest(url, true));
  }
});

interface GalleryComponentProps {
  gifList: GifType[];
}

function GalleryComponent({ gifList }: GalleryComponentProps) {
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state[likeMoveApi.APP_NAME].loading);
  const url = useSelector((state: any) => state[likeMoveApi.APP_NAME].url);
  const lastFetch = useSelector((state: any) => state[likeMoveApi.APP_NAME].lastFetch);
  const params = useSelector((state: any) => state[likeMoveApi.APP_NAME].params);

  return (
    <MasonryComponent
      list={gifList}
      loadMore={() => loadMore({ loading, url, lastFetch, params, dispatch })}
    />
  );
}

function RenderNoMovesFound() {
  const classes = useStyles();
  const boardDetail = useSelector((state: any) => state[boardApi.APP_NAME].detail);

  return (
    <div className={classes.textCenter}>
      <h2 style={{ padding: 20 }}>You don't have any moves saved in {boardDetail.name}</h2>
    </div>
  );
}

function RenderGIFsAll() {
  const classes = useStyles();
  const { isReset } = useGlobal();
  const savedGifList: SavedGifType[] = useSelector(
    (state: any) => state[likeMoveApi.APP_NAME].list,
  );
  const initialized = useSelector((state: any) => state[likeMoveApi.APP_NAME].initialized);

  if (isReset) return null;

  if (!initialized) {
    return (
      <div className={classes.textCenter}>
        <CircularProgress className={classes.progress} size={100} />
      </div>
    );
  }

  if (savedGifList.length === 0) return <RenderNoMovesFound />;
  const gifList = savedGifList
    .map(obj => obj.gif)
    .map(obj => ({
      ...obj,
      src: obj.gif,
      width: Number(obj.gif_width),
      height: Number(obj.gif_height) + CARD_MARGIN,
    }));

  return <GalleryComponent gifList={gifList} />;
}

function RenderGIFsSorted() {
  const classes = useStyles();
  const { isReset } = useGlobal();
  const savedGifList: SavedGifType[] = useSelector(
    (state: any) => state[likeMoveApi.APP_NAME].list,
  );
  const initialized = useSelector((state: any) => state[likeMoveApi.APP_NAME].initialized);

  if (isReset) return null;

  if (!initialized) {
    return (
      <div className={classes.textCenter}>
        <CircularProgress className={classes.progress} size={100} />
      </div>
    );
  }

  if (savedGifList.length === 0) return <RenderNoMovesFound />;
  const gifList = savedGifList
    .map(obj => obj.gif)
    .map(obj => ({
      ...obj,
      src: obj.gif,
      width: Number(obj.gif_width),
      height: Number(obj.gif_height) + CARD_MARGIN,
    }));

  return <GalleryComponent gifList={gifList} />;
}

export default function FavouritePage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector((state: any) => state[likeMoveApi.APP_NAME].loading);
  const initialized = useSelector((state: any) => state[likeMoveApi.APP_NAME].initialized);
  const params = useSelector((state: any) => state[likeMoveApi.APP_NAME].params);
  const boardDetail = useSelector((state: any) => state[boardApi.APP_NAME].detail);

  const [showFolder, setShowFolder] = useState(false);

  useEffect(() => {
    dispatch(gifApi.reset());
    // dispatch(boardApi.getRequest());
    if (boardDetail.id) {
      dispatch(likeMoveApi.setGetParams({ board_id: boardDetail.id }));
      dispatch(likeMoveApi.getRequest());
    }
  }, [boardDetail.id]);

  return (
    <article>
      <StickyContainer>
        <div className="home-page">
          <section className={classes.paddingRoot} style={{ marginBottom: 0 }}>
            <Sticky>
              {({ style, isSticky }) => (
                <div style={{ ...style, zIndex: 5 }}>
                  <Paper className={classes.iconPaperRoot} elevation={1}>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={8}
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      >
                        <Typography variant="h6">{boardDetail.name}</Typography>

                        <IconButton
                          aria-label="Folder"
                          className={classnames(classes.margin, 'tag-scroll')}
                          style={{ margin: 0, padding: 0, marginLeft: 16 }}
                          onClick={() => {
                            setShowFolder(!showFolder);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={!showFolder ? faFolder : faFolderOpen}
                            style={{
                              fontSize: 34,
                              color: !showFolder ? blue[500] : grey[500],
                            }}
                          />
                        </IconButton>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Link to="/" style={{ alignSelf: 'flex-end' }}>
                          <Button color="primary" style={{ textAlign: 'right' }}>
                            Home
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>

                    {isSticky && loading && <LinearProgress color="secondary" />}
                  </Paper>

                  {isSticky && loading && <LinearProgress color="secondary" />}
                </div>
              )}
            </Sticky>
          </section>

          <section style={{ marginTop: 30 }}>
            {params.ordering === 'label__name' ? <RenderGIFsSorted /> : <RenderGIFsAll />}
          </section>

          {initialized && loading && (
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <CircularProgress
                className={classes.tagProgress}
                size={50}
                color="secondary"
                key={0}
              />
            </div>
          )}
        </div>
      </StickyContainer>

      <BackTop style={{ right: 10, bottom: 20 }} />

      {showFolder ? (
        <RenderFolderDialog
          header="Select list to save dance moves"
          handleClose={() => setShowFolder(!showFolder)}
        />
      ) : null}
    </article>
  );
}
