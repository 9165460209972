import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

// App

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Partners() {
  const classes = useStyles();
  const partnerList = [
    {
      title: 'The Library of Dance',
      subtitle: 'A Collection of Social Dance Variations and Music',
      content: (
        <Typography variant="body1" component="h6" gutterBottom>
          Nick Enge is a full-time social dance instructor at the{' '}
          <a href="https://www.libraryofdance.org/ut/" target="_blank" rel="noreferrer">
            University of Texas at Austin (UT)
          </a>
          , where he teaches and researches a wide variety of social dances from the 19th century to
          present and around the world. A dancer since the age of three, Melissa Enge has experience
          in many different dance forms including ballet, jazz, tap, Indian fusion, and social
          dancing. She teaches the social dances classes at UT with Nick.
          <br />
          <br />
          Nick and Melissa, are using their knowledge to create a library of social dance
          variations. Even better, they're making this library available to everyone in the world,
          for free! With videos of more than 1,700 variations posted to date, and hundreds more
          waiting to be filmed, the Library of Dance is always growing. To see their latest videos,
          you can also see our entire collection of demo videos organized by dance at:{' '}
          <a
            href="http://libraryofdance.org/dances"
            target="_blank"
            aria-label="http://libraryofdance.org/dances"
          ></a>
        </Typography>
      ),
      website: 'https://www.libraryofdance.org/',
    },
  ];

  const renderPartners = partnerList.map(p => {
    return (
      <Grid key={p.title} item xs={12} sm={12} md={6} lg={6}>
        <Card className={classes.root} variant="outlined">
          <CardHeader
            title={<Typography variant="h5">{p.title}</Typography>}
            subheader={p.subtitle}
            avatar={
              <Avatar
                alt="The Library of Dance"
                aria-label="The Library of Dance"
                src="https://www.libraryofdance.org/images/redowa.png"
              />
            }
          />

          <CardContent>{p.content}</CardContent>
          <CardActions>
            <Button
              href="https://www.libraryofdance.org/"
              target="_blank"
              size="small"
              color="primary"
              variant="contained"
            >
              Learn More
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  });

  return (
    <Grid container spacing={2} style={{ padding: 10 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h4" style={{ marginBottom: 25 }}>
          Partners
        </Typography>
      </Grid>
      {renderPartners}
    </Grid>
  );
}
