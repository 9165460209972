// Npm
import { createSelector } from 'reselect';

// Constants for Actions
export const APP_NAME = 'notification';

const NOTIFICATION = `${APP_NAME}/NOTIFICATION`;

export const notification = (message: string, status: string) => {
  return {
    type: NOTIFICATION,
    message,
    status,
  };
};

const initial = {
  message: null,
  status: null,
};

export const reducer = (state = initial, action: any) => {
  switch (action.type) {
    case NOTIFICATION: {
      return {
        ...state,
        message: action.message,
        status: action.status,
      };
    }

    default: {
      return state;
    }
  }
};

const selectGlobal = (state: any) => state[APP_NAME];

export const makeSelectBase = (key: string) =>
  createSelector(selectGlobal, globalState => globalState[key]);
