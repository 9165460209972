import { useState, CSSProperties } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import sample from 'lodash.sample';
import find from 'lodash.find';
import { Img } from 'react-image';

// Material UI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LazyLoad from 'react-lazyload';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import red from '@material-ui/core/colors/red';
import indigo from '@material-ui/core/colors/indigo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSnackbar, SnackbarKey, VariantType } from 'notistack';

// App
import { AuthDialog } from 'components/Auth/Modal';
import {
  userAuth as userAuthApi,
  gif as gifApi,
  likeMove as likeMoveApi,
  board as boardApi,
} from 'store/index';
import { useSelector, useDispatch, useGlobal } from 'hooks';
import { GifType } from 'types/api';

import './style.scss';

const COLORS = ['#96ceb4', '#ffeead', '#ff6f69', '#ffcc5c', '#88d8b0'];

const getStartTime = (startTime: string) => {
  if (!startTime) return '';
  if (startTime.indexOf(':') !== -1) {
    // If time in min and seconds
    const st = startTime.split(':');
    return `&t=${Number(st[0] || 0) * 60 + Number(st[1] || 0)}`;
  }
  // Assume seconds
  return `&t=${Math.floor(Number(startTime))}`;
};

const PlaceholderComponent = () => (
  <div
    style={{ width: '100%', height: '100%', backgroundColor: sample(COLORS), position: 'absolute' }}
  />
);

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    margin: theme.spacing(1),
    marginTop: 5,
    marginBottom: 0,
  },
  favourite: {
    float: 'right',
    padding: 10,
    margin: 0,
    color: indigo[500],
  },

  title: {
    color: indigo[500],
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: 16,
  },
}));

interface ImageComponentProps {
  gif: GifType;
  style: CSSProperties;
}

export default function ImageComponent({ gif, style }: ImageComponentProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { getGifRequest, getCurrentBoard } = useGlobal();
  const [authOpen, setAuthOpen] = useState(false);
  const currentUser = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const currentBoard = useSelector((state: any) => state[boardApi.APP_NAME].detail);
  const gifParams = useSelector((state: any) => state[gifApi.APP_NAME].params);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  if (!(gif.gif || gif.optimized_gif)) return '';

  const favouritePage = location.pathname.indexOf('/saved') !== -1;
  const CARD_MARGIN = 40;
  const saved =
    currentBoard && currentBoard.id
      ? find(
          currentUser.favourites || [],
          (x: any) => x.board_id === currentBoard.id && x.gif_id === gif.id,
        )
      : find(currentUser.favourites || [], (x: any) => x.gif_id === gif.id);
  const loaderSize = gif.height - CARD_MARGIN < 200 ? (gif.height - CARD_MARGIN) * 0.5 : 100;
  const gifTitle = gif.label ? (gif.label.name === 'λ(UNKNOWN)' ? '-' : gif.label.name) : '';

  const notify = (name: string, variant: VariantType) => {
    const key: SnackbarKey = enqueueSnackbar(`${name}`, {
      variant,
    });
    setTimeout(() => closeSnackbar(key), 3000);
  };

  return (
    <Card key={gif.id} style={style}>
      {gif?.video?.url && (
        <a
          href={`${gif.video && gif.video.url}${getStartTime(gif.start_time)}`}
          target="_blank"
          style={{ textDecoration: 'none' }}
          rel="noreferrer"
        >
          <CardHeader
            style={{ padding: 8 }}
            classes={{ title: classes.title }}
            avatar={
              <FontAwesomeIcon
                icon={faYoutube}
                style={{
                  fontSize: 28,
                  color: red[500],
                }}
              />
            }
            title={gifTitle}
            subheader={gif.video && gif.video.author}
          />
        </a>
      )}

      <CardContent style={{ padding: 0, height: gif.height + CARD_MARGIN }}>
        <div style={{ height: gif.height - CARD_MARGIN, position: 'relative', display: 'flex' }}>
          <LazyLoad placeholder={<PlaceholderComponent />} height={gif.height - CARD_MARGIN} resize>
            <Img
              src={[
                gif.optimized_gif || gif.gif, // first scenario is if no optimized
                gif.gif, // default in iphone
              ]}
              style={{
                width: `${style.width}px`,
                height: `${gif.height - CARD_MARGIN}px`,
              }}
              loader={
                <div
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#e1e1e1',
                    height: gif.height - CARD_MARGIN,
                    width: '100%',
                  }}
                >
                  <CircularProgress
                    size={loaderSize}
                    style={{
                      marginTop: (gif.height - CARD_MARGIN) / 3,
                    }}
                  />
                </div>
              }
            />
          </LazyLoad>
        </div>

        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={10}>
            <Typography variant="h6" gutterBottom style={{ textTransform: 'capitalize' }}>
              {gif.tags &&
                gif.tags.map((x: any) => (
                  <Chip
                    key={x.id}
                    label={x.name}
                    className={classes.chip}
                    variant={
                      !favouritePage && (gifParams.tags || []).indexOf(x.name) !== -1
                        ? 'default'
                        : 'outlined'
                    }
                    color={
                      !favouritePage && (gifParams.tags || []).indexOf(x.name) !== -1
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() => {
                      if (favouritePage) return;

                      const params = { tags: [x.name] };
                      if (gifParams.tags) {
                        params.tags = [...params.tags, ...gifParams.tags];
                      }
                      dispatch(gifApi.setGetParams({ ...gifParams, ...params }));
                      if (x.primary) navigate(`/label/${x.name}`);
                      getGifRequest();
                    }}
                  />
                ))}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            {saved ? (
              <IconButton
                aria-label="Un favourite"
                className={classes.favourite}
                onClick={() => {
                  if (currentUser && currentUser.id) {
                    dispatch(likeMoveApi.deleteRequest(saved.id)).then(() => {
                      dispatch(userAuthApi.getDetailRequest('user'));
                      getCurrentBoard();
                      notify(`Removed ${gifTitle} from ${currentBoard?.name}`, 'error');
                    });
                  } else {
                    setAuthOpen(true);
                  }
                }}
              >
                <BookmarkIcon style={{ fill: 'black' }} fontSize="medium" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="Favourite"
                className={classes.favourite}
                onClick={() => {
                  if (currentUser && currentUser.id) {
                    if (currentBoard?.id) {
                      dispatch(
                        likeMoveApi.postRequest({ gif_id: gif.id, board_id: currentBoard.id }),
                      ).then(() => {
                        dispatch(userAuthApi.getDetailRequest('user'));
                        getCurrentBoard();
                        notify(`Saved ${gifTitle} to ${currentBoard?.name}`, 'success');
                      });
                    }
                  } else {
                    setAuthOpen(true);
                  }
                }}
              >
                <BookmarkBorderIcon style={{ color: 'black' }} fontSize="medium" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </CardContent>

      {authOpen && (
        <AuthDialog
          open={authOpen}
          onClose={() => setAuthOpen(false)}
          onSuccess={() => setAuthOpen(false)}
        />
      )}
    </Card>
  );
}
