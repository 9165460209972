// Npm
import { put } from 'redux-saga/effects';
import Axios from 'appUtils/axios';

// App
import Base from './api';
import { notification } from './notification';

export default class Video extends Base {
  *getDetail(action) {
    const url = `${this.URL}${action.id}/`;
    try {
      // Call using the request helper
      const resp = yield Axios.get(url);
      yield put(this.getDetailSuccess(resp.data));
      if (resp.data && resp.data.board) {
        const board = new Base('board', '/api/v1/board/board/');
        yield put(board.getDetailSuccess(resp.data.board));
      }
    } catch (err) {
      yield put(this.getDetailError(err));
      yield put(notification(`${this.APP_NAME} get detail error ${err.message}`, 'error'));
    }
  }
}
