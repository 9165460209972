import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import blue from '@material-ui/core/colors/blue';

// App
import { notification, makeSelectBase } from 'appStore/notification';

const styles = theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: red[600],
  },
  warning: {
    backgroundColor: yellow[600],
  },
  info: {
    backgroundColor: blue[600],
  },
  margin: {
    margin: theme.spacing(1),
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

class SimpleSnackbar extends React.Component {
  handleClose = (event, reason) => {
    const { status } = this.props;
    if (reason === 'clickaway') {
      return;
    }

    this.props.setNotification(null, status);
  };

  render() {
    const { classes, message, status } = this.props;
    const Icon = variantIcon[status || 'success'];

    return (
      <Snackbar
        open={message}
        autoHideDuration={status === 'error' ? 5000 : 3000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent
          className={classnames(classes[status], classes.margin)}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={classnames(classes.icon, classes.iconVariant)} />
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    );
  }
}

SimpleSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNotification: (message, status) => dispatch(notification(message, status)),
  };
};

const mapStateToProps = (state, props) => {
  return {
    ...createStructuredSelector({
      message: makeSelectBase('message'),
      status: makeSelectBase('status'),
    })(state, props),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(SimpleSnackbar));
