const dark = 'tw-text-white';
const border = 'tw-border tw-rounded-lg tw-border-solid ';
export const primaryColor = `tw-bg-blue-600 tw-border-blue-600 hover:tw-bg-blue-700`;
export const buttonPrimary = `${primaryColor} ${dark} ${border}`;
export const buttonSecondary = `tw-bg-white tw-text-blue-600 tw-border-blue-600 hover:tw-bg-gray-100 ${border}`;
export const buttonSuccess = `tw-bg-green-600 tw-border-green-600 hover:tw-bg-green-700 ${dark} ${border}`;
export const buttonDanger = `tw-bg-red-600 tw-border-red-600 hover:tw-bg-red-700 ${dark} ${border}`;
export const card = `tw-relative tw-bg-white tw-py-6 tw-px-6 tw-rounded-3xl tw-full tw-my-4 tw-shadow`;
export const buttonMuted = `tw-bg-gray-500 tw-border-gray-400 hover:tw-bg-gray-400 ${dark} ${border}`;
export const buttonBlack = `tw-bg-black tw-border-black ${dark} ${border}`;
