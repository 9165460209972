import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce, throttle } from 'throttle-debounce';
import classnames from 'classnames';
import { StickyContainer, Sticky, StickyChildArgs } from 'react-sticky';
import { AutoComplete, Input, Button } from 'antd';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';

// Material UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import LinearProgress from '@material-ui/core/LinearProgress';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faSortAlphaDown,
  faSortAlphaUp,
  faFire,
  faClock,
  faFolder,
  faFolderOpen,
} from '@fortawesome/free-solid-svg-icons';

// App
import RenderFolderDialog from 'components/Board/FavouriteModal';
import {
  gif as gifApi,
  tag as tagApi,
  label as labelApi,
  userAuth as userAuthApi,
} from 'store/index';
import MasonryComponent from 'components/Masonry';
import { useSelector, useDispatch, useGlobal } from 'hooks';
import { TagType, GifType } from 'types/api';
import { AuthDialog } from 'components/Auth/Modal';
import { buttonBlack } from 'styles/classnames';
import './style.scss';

const CARD_MARGIN = 110;
const LAST_CALL = {
  LOAD_MORE: 'LOAD_MORE',
  GET_FILTERED: 'GET_FILTERED',
  SEARCH: 'SEARCH',
};

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    margin: theme.spacing(1),
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  margin: {
    margin: theme.spacing(1),
  },
  progress: {
    margin: theme.spacing(2),
  },
  tagProgress: {
    margin: '4px',
  },
  textCenter: {
    textAlign: 'center',
  },
  iconPaperRoot: {
    width: '100%',
    padding: '0px 10px',
    zIndex: 5,
    display: 'flex',
  },
  paddingRoot: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 20px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0px 40px',
    },
  },
}));

interface TagComponentProps {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  setAutocomplete: (value: string[]) => void;
  setSearchVisibile: (value: boolean) => void;
}

function TagComponent({
  searchQuery,
  setSearchQuery,
  setAutocomplete,
  setSearchVisibile,
}: TagComponentProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getGifRequest } = useGlobal();
  const gifParams = useSelector((state: any) => state[gifApi.APP_NAME].params);
  const tagList: TagType[] = useSelector((state: any) => state[tagApi.APP_NAME].list);

  const onClickQueryChipDelete = () => {
    const params = { ...gifParams, lastCall: LAST_CALL.SEARCH };
    if (params.search) {
      delete params.search;
    }
    dispatch(gifApi.setGetParams(params));
    getGifRequest();
    setSearchQuery('');
    setAutocomplete([]);
  };

  const onClickTagChipDelete = (name: string) => {
    const tags = (gifParams.tags || []).filter((x: any) => x !== name);
    setSearchVisibile(false);
    setSearchQuery('');
    dispatch(gifApi.setGetParams({ ...gifParams, tags, lastCall: LAST_CALL.GET_FILTERED }));
    navigate(`/`);
    getGifRequest();
  };

  return (
    <>
      {tagList
        ?.filter(x => !x.primary && (gifParams.tags || []).indexOf(x.name) !== -1)
        .map(({ id, name }) => {
          return (
            <Chip
              key={id}
              label={name}
              onDelete={() => onClickTagChipDelete(name)}
              onClick={() => onClickTagChipDelete(name)}
              className={classnames(classes.chip, 'tag-scroll')}
              color="primary"
            />
          );
        })}

      {searchQuery && gifParams.search && (
        <Chip
          icon={<SearchIcon />}
          label={gifParams.search}
          onDelete={onClickQueryChipDelete}
          onClick={onClickQueryChipDelete}
          className={classnames(classes.chip, 'tag-scroll')}
          color="primary"
        />
      )}

      {tagList
        ?.filter(x => x.primary)
        .map(({ id, name }) => {
          if ((gifParams.tags || []).indexOf(name) !== -1) {
            return (
              <Chip
                key={id}
                label={name}
                onDelete={() => onClickTagChipDelete(name)}
                onClick={() => onClickTagChipDelete(name)}
                className={classnames(classes.chip, 'tag-scroll')}
                color="primary"
              />
            );
          }

          return (
            <Chip
              key={id}
              label={name}
              onClick={() => {
                const params = { tags: [name] };
                dispatch(
                  gifApi.setGetParams({
                    ...gifParams,
                    ...params,
                    lastCall: LAST_CALL.GET_FILTERED,
                  }),
                );
                navigate(`/label/${name}`);
                getGifRequest();
              }}
              className={classnames(classes.chip, 'tag-scroll')}
              variant="outlined"
              color="primary"
            />
          );
        })}
    </>
  );
}

interface TagComponentMobileStickyProps {
  showFolder: boolean;
  setShowFolder: (value: boolean) => void;
}

function TagComponentMobileSticky({ showFolder, setShowFolder }: TagComponentMobileStickyProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getGifRequest } = useGlobal();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchVisible, setSearchVisibile] = useState(false);
  const [autoCompleteList, setAutocomplete] = useState<string[]>([]);

  const gifParams = useSelector((state: any) => state[gifApi.APP_NAME].params);
  const labelList: string[] = useSelector((state: any) => state[labelApi.APP_NAME].list);
  const currentUser = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  const searchAutoComplete = useRef(
    throttle(100, (name, labels: string[]) => {
      if (!name) {
        setAutocomplete([]);
      } else {
        // wierd bug where labels are not initialized
        const moves: string[] = labels.filter(
          x => x.toLowerCase().indexOf(name.toLowerCase()) !== -1,
        );
        setAutocomplete(moves.length > 10 ? moves.slice(0, 10) : moves);
      }
    }),
  );

  useEffect(() => {
    searchAutoComplete.current(searchQuery, labelList);
  }, [searchQuery]);

  useEffect(() => {
    setSearchVisibile(false);
  }, [gifParams]);

  return (
    <div className="tags-scrolling-wrapper-flexbox" style={{ padding: 5, width: '100%' }}>
      {
        <>
          {searchVisible ? (
            <AutoComplete
              value={searchQuery}
              style={{ width: '300px', marginTop: 5, marginRight: 16 }}
              size="large"
              dataSource={autoCompleteList}
              onChange={name => {
                setSearchQuery(name);
              }}
              onSelect={value => {
                setSearchQuery(value);
                setSearchVisibile(false);
                if (value) {
                  dispatch(
                    gifApi.setGetParams({
                      ...gifParams,
                      search: value,
                      lastCall: LAST_CALL.SEARCH,
                    }),
                  );
                  getGifRequest();
                }
              }}
            >
              <Input
                prefix={<CloseCircleOutlined onClick={() => setSearchVisibile(false)} />}
                className={classnames('tw-border-black tw-border tw-border-solid')}
                style={{ width: '300px' }}
                suffix={
                  <Button
                    className={buttonBlack}
                    size="large"
                    type="primary"
                    onClick={() => {
                      if (searchQuery) {
                        dispatch(
                          gifApi.setGetParams({
                            ...gifParams,
                            search: searchQuery,
                            lastCall: LAST_CALL.SEARCH,
                          }),
                        );
                        getGifRequest();
                      }
                      setSearchQuery('');
                    }}
                  >
                    <SearchOutlined />
                  </Button>
                }
              />
            </AutoComplete>
          ) : (
            <IconButton
              aria-label="View List"
              className={classnames(classes.margin, 'tag-scroll')}
              style={{ margin: 0, padding: 0, marginRight: 16, marginLeft: 16 }}
              onClick={() => {
                setSearchVisibile(!searchVisible);
              }}
            >
              <FontAwesomeIcon
                icon={faSearch}
                style={{
                  fontSize: 34,
                  marginTop: 4,
                  color: searchVisible ? orange[500] : 'inherit',
                }}
              />
            </IconButton>
          )}

          <IconButton
            aria-label="Sort by hot"
            className={classnames(classes.margin, 'tag-scroll')}
            style={{ margin: 0, padding: 0, marginRight: 16 }}
            onClick={() => {
              if (!gifParams.ordering) return;
              const params = { ...gifParams, lastCall: LAST_CALL.GET_FILTERED };
              delete params.ordering;

              dispatch(gifApi.setGetParams(params));
              getGifRequest();
            }}
          >
            <FontAwesomeIcon
              icon={faFire}
              style={{
                fontSize: 34,
                marginTop: 4,
                color: !gifParams.ordering ? blue[500] : 'inherit',
              }}
            />
          </IconButton>

          <IconButton
            aria-label="Sort by date"
            className={classnames(classes.margin, 'tag-scroll')}
            style={{ margin: 0, padding: 0, marginRight: 16 }}
            onClick={() => {
              if (gifParams.ordering === '-created_at') return;
              dispatch(
                gifApi.setGetParams({
                  ...gifParams,
                  ordering: '-created_at',
                  lastCall: LAST_CALL.GET_FILTERED,
                }),
              );
              getGifRequest();
            }}
          >
            <FontAwesomeIcon
              icon={faClock}
              style={{
                fontSize: 34,
                marginTop: 4,
                color: gifParams.ordering === '-created_at' ? blue[500] : 'inherit',
              }}
            />
          </IconButton>

          <IconButton
            aria-label="Sort Alphabetically"
            className={classnames(classes.margin, 'tag-scroll')}
            style={{ margin: 0, padding: 0, marginRight: 16 }}
            onClick={() => {
              dispatch(
                gifApi.setGetParams({
                  ...gifParams,
                  ordering: gifParams.ordering === 'label__name' ? '-label__name' : 'label__name',
                  lastCall: LAST_CALL.GET_FILTERED,
                }),
              );
              getGifRequest();
            }}
          >
            <FontAwesomeIcon
              icon={gifParams.ordering === 'label__name' ? faSortAlphaUp : faSortAlphaDown}
              style={{
                fontSize: 34,
                marginTop: 4,
                color:
                  (gifParams.ordering || '').indexOf('label__name') !== -1 ? blue[500] : 'inherit',
              }}
            />
          </IconButton>

          {currentUser && currentUser.id ? (
            <IconButton
              aria-label="Folder"
              className={classnames(classes.margin, 'tag-scroll')}
              style={{ margin: 0, padding: 0, marginRight: 16 }}
              onClick={() => {
                setShowFolder(!showFolder);
              }}
            >
              <FontAwesomeIcon
                icon={!showFolder ? faFolder : faFolderOpen}
                style={{
                  fontSize: 34,
                  marginTop: 4,
                  color: showFolder ? blue[500] : 'inherit',
                }}
              />
            </IconButton>
          ) : null}

          <TagComponent
            searchQuery={searchQuery}
            setAutocomplete={setAutocomplete}
            setSearchQuery={setSearchQuery}
            setSearchVisibile={setSearchVisibile}
          />
        </>
      }
    </div>
  );
}

function RenderNoMovesFound() {
  const classes = useStyles();

  return (
    <div className={classes.textCenter}>
      <h2 style={{ padding: 20 }}>
        No Moves found for the selected combination of tags and/or search, please try another
        combination.
      </h2>
    </div>
  );
}

const loadMore = debounce(500, ({ loading, url, lastFetch, gifParams, dispatch }) => {
  if (
    !loading && // Must not be loading
    url && // The next URL must be set
    lastFetch &&
    Math.floor(Date.now() / 1000) - lastFetch > 2 // Giving some time to populate the DOM
  ) {
    dispatch(gifApi.setGetParams({ ...gifParams, lastCall: LAST_CALL.LOAD_MORE }));
    dispatch(gifApi.getRequest(url, true));
  }
});

interface GalleryComponentProps {
  gifList: GifType[];
}

function GalleryComponent({ gifList }: GalleryComponentProps) {
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state[gifApi.APP_NAME].loading);
  const url = useSelector((state: any) => state[gifApi.APP_NAME].url);
  const lastFetch = useSelector((state: any) => state[gifApi.APP_NAME].lastFetch);
  const gifParams = useSelector((state: any) => state[gifApi.APP_NAME].params);

  return (
    <MasonryComponent
      list={gifList}
      loadMore={() => loadMore({ loading, url, lastFetch, gifParams, dispatch })}
    />
  );
}

function RenderGIFsAll() {
  const classes = useStyles();
  const { isReset } = useGlobal();
  let gifList: GifType[] = useSelector((state: any) => state[gifApi.APP_NAME].list);
  const initialized = useSelector((state: any) => state[gifApi.APP_NAME].initialized);

  if (isReset) {
    return (
      <div className={classes.textCenter}>
        <CircularProgress className={classes.progress} size={100} />
      </div>
    );
  }

  if (!initialized) {
    return (
      <div className={classes.textCenter}>
        <CircularProgress className={classes.progress} size={100} />
      </div>
    );
  }

  if (gifList.length === 0) return <RenderNoMovesFound />;
  gifList = gifList.map(obj => ({
    ...obj,
    src: obj.gif,
    width: Number(obj.gif_width),
    height: Number(obj.gif_height) + CARD_MARGIN,
  }));

  return <GalleryComponent gifList={gifList} />;
}

function RenderGIFsSorted() {
  const classes = useStyles();
  const { isReset } = useGlobal();
  let gifList: GifType[] = useSelector((state: any) => state[gifApi.APP_NAME].list);
  const initialized = useSelector((state: any) => state[gifApi.APP_NAME].initialized);
  if (isReset) {
    return (
      <div className={classes.textCenter}>
        <CircularProgress className={classes.progress} size={100} />
      </div>
    );
  }

  if (!initialized) {
    return (
      <div className={classes.textCenter}>
        <CircularProgress className={classes.progress} size={100} />
      </div>
    );
  }

  if (gifList.length === 0) return <RenderNoMovesFound />;
  gifList = gifList.map(obj => ({
    ...obj,
    src: obj.gif,
    width: Number(obj.gif_width),
    height: Number(obj.gif_height) + CARD_MARGIN,
  }));

  return <GalleryComponent gifList={gifList} />;
}

interface SelectListSubMenuProps {
  showFolder: boolean;
  setShowFolder: (value: boolean) => void;
}

function SelectListSubMenu({ showFolder, setShowFolder }: SelectListSubMenuProps) {
  const { user, currentBoard, userInitialized } = useGlobal();
  const classes = useStyles();
  const [authOpen, setAuthOpen] = useState(false);

  if (userInitialized && !user?.id) {
    return (
      <section className={classes.paddingRoot} style={{ marginTop: 10 }}>
        <Chip
          label="Register to save dance moves"
          className={classnames(classes.chip, 'tag-scroll')}
          color="primary"
          onClick={() => setAuthOpen(true)}
        />

        {authOpen && (
          <AuthDialog
            open={authOpen}
            onClose={() => setAuthOpen(false)}
            onSuccess={() => setAuthOpen(false)}
          />
        )}
      </section>
    );
  }

  if (currentBoard?.id) {
    const label = (
      <>
        <FontAwesomeIcon
          icon={!showFolder ? faFolder : faFolderOpen}
          style={{
            fontSize: 16,
            marginRight: 10,
            color: showFolder ? blue[500] : 'inherit',
          }}
        />
        Current List: {currentBoard.name} ({currentBoard.favourite_count} moves)
      </>
    );
    return (
      <section className={classes.paddingRoot} style={{ marginTop: 10 }}>
        <Chip
          label={label}
          className={classnames(classes.chip, 'tag-scroll')}
          color="primary"
          onClick={() => setShowFolder(true)}
        />
      </section>
    );
  }

  return <span />;
}

export default function DancePage() {
  const urlParams = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector((state: any) => state[gifApi.APP_NAME].loading);
  const initialized = useSelector((state: any) => state[gifApi.APP_NAME].initialized);
  const params = useSelector((state: any) => state[gifApi.APP_NAME].params);
  const [showFolder, setShowFolder] = useState(false);

  const newParams: { tags?: string[] } = {};

  useEffect(() => {
    if (urlParams && urlParams.tag) {
      newParams.tags = [urlParams.tag];
    }

    dispatch(gifApi.setGetParams({ ...params, ...newParams /*ordering: '-created_at'*/ }));

    dispatch(tagApi.getRequest());
    dispatch(gifApi.getRequest());
    dispatch(labelApi.getRequest('/api/v1/label/label/autocomplete_all/'));
  }, []);

  return (
    <article>
      <StickyContainer>
        <div className="home-page">
          <section className={classes.paddingRoot} style={{ marginBottom: 0 }}>
            <Sticky>
              {({ style, isSticky }: StickyChildArgs) => (
                <div style={{ ...style, zIndex: 5 }}>
                  <Paper className={classes.iconPaperRoot} elevation={1}>
                    <TagComponentMobileSticky
                      showFolder={showFolder}
                      setShowFolder={setShowFolder}
                    />
                    {isSticky && loading && <LinearProgress color="secondary" />}
                  </Paper>

                  {isSticky && loading && <LinearProgress color="secondary" />}
                </div>
              )}
            </Sticky>
          </section>

          <SelectListSubMenu showFolder={showFolder} setShowFolder={setShowFolder} />

          <section style={{ marginTop: 10 }}>
            {params.ordering === 'label__name' ? <RenderGIFsSorted /> : <RenderGIFsAll />}
          </section>

          {initialized && loading && (
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <CircularProgress
                className={classes.tagProgress}
                size={50}
                color="secondary"
                key={0}
              />
            </div>
          )}
        </div>
      </StickyContainer>

      {showFolder ? (
        <RenderFolderDialog
          header="Select list to save dance moves"
          handleClose={() => setShowFolder(!showFolder)}
        />
      ) : null}

      {/* <BackTop style={{ right: 10, bottom: 100 }}/> */}
    </article>
  );
}
