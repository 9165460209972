import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

// core components
import { useDispatch } from 'hooks';
import Axios from 'utils/axios';
import CustomInput from 'components/CustomInput/CustomInput';
import { userAuth as userAuthApi } from 'store/index';
import { buttonBlack, buttonMuted } from 'styles/classnames';

const useStyles = makeStyles({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

interface EmailAuthProps {
  onSuccess?: VoidFunction;
  isLogin: boolean;
}

interface ErrorType {
  all?: string;
  email?: string;
  password?: string;
  password1?: string;
  password2?: string;
}

interface CredentialsType {
  email?: string;
  password?: string;
  password1?: string;
  password2?: string;
}

export default function LoginPage({ isLogin, onSuccess }: EmailAuthProps) {
  const [credentials, setCredentials] = useState<CredentialsType>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorType>({});

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let enabled = false;
  if (isLogin) {
    enabled = Boolean(credentials.email && credentials.password);
  } else {
    enabled = Boolean(credentials.email && credentials.password1 && credentials.password2);
  }

  function handleLogin() {
    setIsLoading(true);
    Axios.post(
      isLogin ? '/api/v1/rest-auth/login/' : '/api/v1/rest-auth/registration/',
      credentials,
    )
      .then(() => {
        dispatch(userAuthApi.getDetailRequest('user')).then(() => {
          setIsLoading(false);
          onSuccess ? onSuccess() : navigate('/');
        });
      })
      .catch(err => {
        if (err.response && err.response.status === 400 && err.response.data) {
          const { email, password, password1, password2, non_field_errors } = err.response.data;
          const _error: ErrorType = {};
          if (email) _error.email = email[0];
          if (password) _error.password = password[0];
          if (password1) _error.password1 = password1[0];
          if (password2) _error.password2 = password2[0];
          if (non_field_errors) _error.all = non_field_errors[0];
          setError(_error);
        } else {
          console.error(err);
          setError({ all: 'Oops sorry. Something went wrong.' });
        }
        setIsLoading(false);
      });
  }

  function onKeyDown(e: any) {
    if (e.keyCode == 13 && enabled) {
      handleLogin();
    }
  }

  const passwordComponent = isLogin ? (
    <>
      <CustomInput
        labelText={error.password ? error.password : 'Password'}
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'password',
          autoComplete: 'off',
          value: credentials.password,
          variant: 'outlined',
          onChange: (ev: any) => setCredentials({ ...credentials, password: ev.target.value }),
          onKeyDown,
        }}
        error={!!error.password}
      />
    </>
  ) : (
    <>
      <CustomInput
        labelText={error.password1 ? error.password1 : 'Password'}
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'password',
          autoComplete: 'off',
          value: credentials.password1,
          variant: 'outlined',
          onChange: (ev: any) => setCredentials({ ...credentials, password1: ev.target.value }),
          onKeyDown,
        }}
        error={!!error.password1}
      />

      <CustomInput
        labelText={error.password2 ? error.password2 : 'Confirm Password'}
        id="pass2"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'password',
          autoComplete: 'off',
          value: credentials.password2,
          variant: 'outlined',
          onChange: (ev: any) => setCredentials({ ...credentials, password2: ev.target.value }),
          onKeyDown,
        }}
        error={!!error.password2}
      />
    </>
  );

  let renderErrorAll;
  if (error.all) {
    renderErrorAll = (
      <Grid item xs={12} sm={12} md={12} style={{ marginTop: 15 }}>
        <Typography variant="body2" gutterBottom color="error">
          {error.all}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid justify="center">
      {renderErrorAll}
      <Grid item xs={12} sm={12} md={12}>
        <CustomInput
          labelText={error.email ? error.email : 'Email...'}
          id="email"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'email',
            onChange: (ev: any) => setCredentials({ ...credentials, email: ev.target.value }),
            value: credentials.email,
            variant: 'outlined',
            onKeyDown,
          }}
          error={!!error.email}
        />

        {passwordComponent}
      </Grid>

      <Grid item xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
        {/* @ts-ignore */}
        <Button
          variant="contained"
          className={classnames(enabled ? buttonBlack : buttonMuted, 'tw-w-full')}
          disabled={!enabled || isLoading}
          fullWidth
          onClick={handleLogin}
        >
          {isLogin ? 'Login' : 'Sign Up'}
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </Grid>
    </Grid>
  );
}
