import React from 'react';
import { connect } from 'react-redux';
import { forceCheck } from 'react-lazyload';
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import withStyles from '@material-ui/core/styles/withStyles';
import { createStructuredSelector } from 'reselect';

import ImageComponent from 'components/ImageComponent/index';
import SegmentImageComponent from 'components/ImageComponent/Segment';
import SharedImageComponent from 'components/ImageComponent/Shared';
import InternalImageComponent from 'components/ImageComponent/Internal';

import {
  Masonry,
  createCellPositioner as createMasonryCellPositioner,
} from 'react-virtualized/dist/es/Masonry';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import { WindowScroller } from 'react-virtualized/dist/es/WindowScroller';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized/dist/es/CellMeasurer';

// App
import { makeSelectBase as appMakeSelectBase } from 'appStore/app';
import { breakpoints } from 'appUtils/core';
import MobileComponent from './Mobile';

class MasonryComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this._columnCount = 0;

    this._cache = new CellMeasurerCache({
      defaultHeight: 300,
      defaultWidth: 200,
      fixedWidth: true,
    });

    this.state = {
      columnWidth: 400,
      height: 800,
      gutterSize: 10,
      overscanByPixels: 30,
      windowScrollerEnabled: props.disableWindowScroll ? false : true,
      dimensions: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.list) !== JSON.stringify(prevProps.list)) {
      this._reset();
      scroll.scrollMore(1); // Needed to fix Masonry rendering bug
    }
  }

  componentDidMount() {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  renderDesktop() {
    const { classes, rootOverride = {} } = this.props;
    const { height, overscanByPixels, windowScrollerEnabled } = this.state;

    let child;

    if (windowScrollerEnabled) {
      child = (
        <WindowScroller overscanByPixels={overscanByPixels}>{this._renderAutoSizer}</WindowScroller>
      );
    } else {
      child = this._renderAutoSizer({ height });
    }

    return (
      <div className={classes.root} style={{ ...rootOverride }}>
        {child}
      </div>
    );
  }

  render() {
    const { dimensions } = this.props;
    if (breakpoints.isMobile(dimensions && dimensions.width))
      return <MobileComponent {...this.props} />;
    return this.renderDesktop();
  }

  renderImageComponent(style, index) {
    const { list, reset, type } = this.props;
    const { columnWidth } = this.state;
    const datum = list[index % list.length];
    if (type === 'internal') {
      return (
        <InternalImageComponent
          segment={datum}
          style={{ ...style, width: columnWidth }}
          reset={reset}
        />
      );
    } else if (type === 'segment') {
      return (
        <SegmentImageComponent
          {...style}
          segment={datum}
          style={{ ...style, width: columnWidth }}
          reset={reset}
        />
      );
    } else if (type === 'shared') {
      return (
        <SharedImageComponent
          {...style}
          segment={datum}
          style={{ ...style, width: columnWidth }}
          reset={reset}
        />
      );
    } else {
      return (
        <ImageComponent
          key={datum.id}
          gif={datum}
          {...style}
          style={{ ...style, width: columnWidth }}
          reset={reset}
        />
      );
    }
  }

  _calculateColumnCount() {
    const { columnWidth, gutterSize } = this.state;

    this._columnCount = Math.floor(this._width / (columnWidth + gutterSize));
    return this._columnCount;
  }

  _cellRenderer = ({ index, key, parent, style, columnIndex, rowIndex }) => {
    return (
      <CellMeasurer
        cache={this._cache}
        index={index}
        key={key}
        parent={parent}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
      >
        {this.renderImageComponent(style, index)}
      </CellMeasurer>
    );
  };

  _initCellPositioner() {
    if (typeof this._cellPositioner === 'undefined') {
      const { columnWidth, gutterSize } = this.state;
      this._cellPositioner = createMasonryCellPositioner({
        cellMeasurerCache: this._cache,
        columnCount: this._columnCount,
        columnWidth,
        spacer: gutterSize,
      });
    }
  }

  _onResize = ({ width }) => {
    this._width = width;

    this._calculateColumnCount();
    this._resetCellPositioner();
    this._masonry.recomputeCellPositions();
    forceCheck();
    scroll.scrollMore(1); // Needed to fix Masonry rendering bug
  };

  _renderAutoSizer = ({ height, scrollTop }) => {
    this._height = height;
    this._scrollTop = scrollTop;

    const { overscanByPixels } = this.state;
    return (
      <AutoSizer
        disableHeight
        height={height}
        onResize={this._onResize}
        overscanByPixels={overscanByPixels}
        scrollTop={this._scrollTop}
        style={{
          width: 'auto' /* If not set, default is zero and therefore not centered */,
          marginBottom: 30,
        }}
      >
        {this._renderMasonry}
      </AutoSizer>
    );
  };

  _renderMasonry = ({ width }) => {
    if (!this._columnCount) this._width = width;

    this._calculateColumnCount();
    this._initCellPositioner();

    const { list, loadMore } = this.props;
    const { columnWidth, height, overscanByPixels, windowScrollerEnabled, gutterSize } = this.state;
    const masonryWidth = Math.min(
      Math.max(280, width),
      Math.max((columnWidth + gutterSize) * this._columnCount + 2, 280),
    );

    return (
      <Masonry
        autoHeight={windowScrollerEnabled}
        cellCount={list.length}
        cellMeasurerCache={this._cache}
        cellPositioner={this._cellPositioner}
        cellRenderer={this._cellRenderer}
        height={windowScrollerEnabled ? this._height : height}
        overscanByPixels={overscanByPixels}
        ref={this._setMasonryRef}
        scrollTop={this._scrollTop}
        width={masonryWidth}
        style={{ margin: '0 auto' }}
        onCellsRendered={({ startIndex, stopIndex }) => {
          if (list.length - stopIndex < 10) loadMore();
          forceCheck();
        }}
        tabIndex={null}
      />
    );
  };

  _reset = () => {
    // this._cache.clearAll();
    // this._resetCellPositioner();
    // this._masonry.clearCellPositions();

    this._calculateColumnCount();
    this._resetCellPositioner();
    this._masonry.recomputeCellPositions();
    forceCheck();
    scroll.scrollMore(1);
  };

  _resetCellPositioner() {
    const { columnWidth, gutterSize } = this.state;
    this._cellPositioner.reset({
      columnCount: this._columnCount,
      columnWidth,
      spacer: gutterSize,
    });
  }

  _setMasonryRef = ref => {
    this._masonry = ref;
  };
}

const mapStateToProps = (state, props) => {
  return {
    ...createStructuredSelector({
      dimensions: appMakeSelectBase('dimensions'),
    })(state, props),
  };
};

const styles = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: '0px 10px',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 30px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0px 40px',
    },
  },

  mobileContent: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
});

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles, { withTheme: true })(MasonryComponent));
