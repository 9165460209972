// Npm
import { put } from 'redux-saga/effects';
import Axios from 'appUtils/axios';

// App
import Base from './api';
import { notification } from './notification';

export default class YouTube extends Base {
  *post(action) {
    try {
      const resp = yield Axios({
        method: 'post',
        url: this.URL,
        data: action.data,
      });
      yield put(this.postSuccess(resp.data));
      yield put(notification(`${this.APP_NAME} create success`, 'success'));
    } catch (err) {
      yield put(notification(`${this.APP_NAME} create error ${err.message}`, 'error'));
    }
  }
}
