import { useContext } from 'react';
import { GlobalContext, GlobalContextProps } from 'context/GlobalContext';
// ----------------------------------------------------------------------

export default function useGlobalContext(): GlobalContextProps {
  const context = useContext(GlobalContext);

  if (!context) throw new Error('Global context must be use inside GlobalProvider');

  return context;
}
