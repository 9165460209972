import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

// Material
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';

// App
import { AuthDialog } from 'components/Auth/Modal';
import { gif as gifApi } from 'store/index';
import { useSelector, useGlobal } from 'hooks';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  drawerContent: {
    width: 250,
    height: '100%',
    background: '#212121',
  },

  listItemPrimaryMobile: {
    color: 'white',
  },

  primary: {
    color: 'rgba(0, 0, 0, 0.87)',
  },

  inset: {
    flex: '1 1 auto',
    padding: '0 16px',
    minWidth: 0,
  },
}));

interface RenderDrawerProps {
  drawerOpen: boolean | undefined;
  drawerOnClose: (value: any) => void;
  handleClose: VoidFunction;
  setAuthOpen: (value: boolean) => void;
}

function RenderDrawer({ drawerOpen, drawerOnClose, handleClose, setAuthOpen }: RenderDrawerProps) {
  const classes = useStyles();
  const { user, logout } = useGlobal();

  return (
    <Drawer open={drawerOpen} onClose={drawerOnClose}>
      <div className={classes.drawerContent} onClick={drawerOnClose} onKeyDown={drawerOnClose}>
        <List>
          {user?.id ? (
            <Fragment>
              <MenuItem
                style={{
                  color: 'white',
                  textDecoration: 'none',
                  borderBottom: '1px solid white',
                }}
              >
                <ListItemIcon>
                  <PersonIcon style={{ fill: blue[500] }} />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemPrimaryMobile }}
                  inset
                  primary={`Hi ${user.username}`}
                />
              </MenuItem>

              <Link
                className="router-link"
                to="/"
                style={{ color: 'white', textDecoration: 'none' }}
              >
                <ListItem button onClick={drawerOnClose}>
                  <ListItemIcon>
                    <HomeIcon style={{ fill: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.listItemPrimaryMobile }}
                    inset
                    primary="Home"
                  />
                </ListItem>
              </Link>

              {user.is_staff && (
                <a
                  className="router-link"
                  href="/dashboard"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  <ListItem button onClick={drawerOnClose}>
                    <ListItemIcon>
                      <DashboardIcon style={{ fill: 'white' }} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemPrimaryMobile }}
                      inset
                      primary="Dashboard"
                    />
                  </ListItem>
                </a>
              )}

              <Link
                className="router-link"
                to={`/saved/`}
                style={{ color: 'white', textDecoration: 'none' }}
              >
                <ListItem button onClick={drawerOnClose}>
                  <ListItemIcon>
                    <BookmarkIcon style={{ fill: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.listItemPrimaryMobile }}
                    inset
                    primary="Saved"
                  />
                </ListItem>
              </Link>

              <ListItem button onClick={logout}>
                <ListItemIcon>
                  <ExitToAppIcon style={{ fill: 'white' }} />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemPrimaryMobile }}
                  inset
                  primary="Logout"
                />
              </ListItem>
            </Fragment>
          ) : (
            <ListItem
              button
              onClick={() => {
                setAuthOpen(true);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AccountCircleIcon style={{ fill: 'white' }} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemPrimaryMobile }}
                inset
                primary="Login"
              />
            </ListItem>
          )}

          <Divider style={{ backgroundColor: 'rgba(192, 192, 192, 0.5)' }} />

          <Link
            className="router-link"
            to="/partners/"
            style={{ color: 'white', textDecoration: 'none' }}
          >
            <ListItem button onClick={drawerOnClose}>
              <ListItemIcon>
                <GroupWorkIcon style={{ fill: 'white' }} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemPrimaryMobile }}
                inset
                primary="Partners"
              />
            </ListItem>
          </Link>

          {/* <a href="http://blog.dancelogue.com/" style={{ color: 'white', textDecoration: 'none' }}>
              <ListItem button onClick={drawerOnClose}>
                <ListItemIcon className={classes.icon}>
                  <ImportContactIcon style={{ fill: 'white' }} />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemPrimaryMobile }} inset primary="Blog" />
              </ListItem>
            </a>

            <a href="https://blog.dancelogue.com/about-us/" style={{ color: 'white', textDecoration: 'none' }}>
              <ListItem button onClick={drawerOnClose}>
                <ListItemIcon className={classes.icon}>
                  <CreateIcon style={{ fill: 'white' }} />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemPrimaryMobile }} inset primary="About" />
              </ListItem>
            </a> */}

          <a
            href="mailto:info@mbele.io"
            target="_blank"
            style={{ color: 'white', textDecoration: 'none' }}
            rel="noreferrer"
          >
            <ListItem button onClick={drawerOnClose}>
              <ListItemIcon>
                <PhoneIcon style={{ fill: 'white' }} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemPrimaryMobile }}
                inset
                primary="Contact Us"
              />
            </ListItem>
          </a>
        </List>
      </div>
    </Drawer>
  );
}

interface RenderMenuProps {
  handleClose: VoidFunction;
  anchorEl: null | HTMLElement;
  setAuthOpen: (value: boolean) => void;
}

function RenderMenu({ anchorEl, handleClose, setAuthOpen }: RenderMenuProps) {
  const classes = useStyles();
  const { user, logout } = useGlobal();

  return (
    <Fragment>
      <Menu
        id="User Menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableAutoFocusItem
      >
        <MenuList>
          <Link className="router-link" to="/" style={{ color: 'white', textDecoration: 'none' }}>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <HomeIcon style={{ fill: grey[900] }} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.primary, inset: classes.inset }}
                inset
                primary="Home"
              />
            </MenuItem>
          </Link>

          {user?.id ? (
            <Fragment>
              {user.is_staff && (
                <a
                  className="router-link"
                  href="/dashboard"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <DashboardIcon style={{ fill: blue[500] }} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.primary, inset: classes.inset }}
                      inset
                      primary="Dashboard"
                    />
                  </MenuItem>
                </a>
              )}

              <Link
                className="router-link"
                to="/saved/"
                style={{ color: 'white', textDecoration: 'none' }}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <BookmarkIcon style={{ fill: '#000' }} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.primary, inset: classes.inset }}
                    inset
                    primary="Saved"
                  />
                </MenuItem>
              </Link>

              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <ExitToAppIcon style={{ fill: grey[900] }} />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.primary, inset: classes.inset }}
                  inset
                  primary="Logout"
                />
              </MenuItem>
            </Fragment>
          ) : (
            <MenuItem
              onClick={() => {
                setAuthOpen(true);
                handleClose();
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon style={{ fill: grey[900] }} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.primary, inset: classes.inset }}
                inset
                primary="Login"
              />
            </MenuItem>
          )}

          <Divider />

          <Link
            className="router-link"
            to="/partners/"
            style={{ color: 'white', textDecoration: 'none' }}
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <GroupWorkIcon style={{ fill: grey[900] }} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.primary, inset: classes.inset }}
                inset
                primary="Partners"
              />
            </MenuItem>
          </Link>

          {/* <a href="http://blog.dancelogue.com/" style={{ textDecoration: 'none' }}>
              <MenuItem className={classes.menuItem}>
                <ListItemIcon className={classes.icon}>
                  <ImportContactIcon style={{ fill: grey[900] }} />
                </ListItemIcon>

                <ListItemText classes={{ primary: classes.primary, inset: classes.inset }} inset primary="Blog" />
              </MenuItem>
            </a>

            <a href="https://blog.dancelogue.com/about-us/" style={{ textDecoration: 'none' }}>
              <MenuItem className={classes.menuItem}>
                <ListItemIcon className={classes.icon}>
                  <InfoIcon style={{ fill: grey[900] }} />
                </ListItemIcon>

                <ListItemText classes={{ primary: classes.primary, inset: classes.inset }} inset primary="About" />
              </MenuItem>
            </a> */}

          <a
            style={{ textDecoration: 'none' }}
            href="mailto:info@mbele.io"
            target="_blank"
            rel="noreferrer"
          >
            <MenuItem>
              <ListItemIcon>
                <AlternateEmailIcon style={{ fill: grey[900] }} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.primary, inset: classes.inset }}
                inset
                primary="Contact Us"
              />
            </MenuItem>
          </a>
        </MenuList>
      </Menu>
    </Fragment>
  );
}

export default function Header() {
  const classes = useStyles();
  const gifLoading = useSelector((state: any) => state[gifApi.APP_NAME].loading);
  const [authOpen, setAuthOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = useGlobal();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {/* @ts-ignore */}
              <Hidden only={['md', 'lg', 'xl']}>
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Menu"
                  onClick={() => setDrawerOpen(!drawerOpen)}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>

              <Link
                className="router-link"
                to="/"
                style={{ color: 'white', textDecoration: 'none' }}
              >
                <Button size="large" color="inherit">
                  <h1
                    style={{
                      fontSize: 20,
                      margin: 0,
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                  >
                    The Dancelogue Project
                  </h1>
                </Button>
              </Link>
            </Grid>

            {/* @ts-ignore */}
            <Hidden only={['sm', 'xs']}>
              <Grid
                item
                xs={6}
                lg={6}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
              >
                {user?.id ? <Typography variant="body1">Hi {user.username}</Typography> : null}
                <IconButton
                  style={{ float: 'right' }}
                  color="inherit"
                  aria-label="Menu"
                  onClick={event => setAnchorEl(event.currentTarget)}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>

              <RenderMenu
                setAuthOpen={setAuthOpen}
                handleClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
              />
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>

      {gifLoading && <LinearProgress color="secondary" />}

      {/* @ts-ignore */}
      <Hidden only={['md', 'lg', 'xl']}>
        <RenderDrawer
          drawerOnClose={() => setDrawerOpen(false)}
          drawerOpen={drawerOpen}
          handleClose={() => setDrawerOpen(false)}
          setAuthOpen={setAuthOpen}
        />
      </Hidden>

      {authOpen && (
        <AuthDialog open onClose={() => setAuthOpen(false)} onSuccess={() => setAuthOpen(false)} />
      )}
    </div>
  );
}
