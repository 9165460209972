/** @flow */
import React from 'react';
import { connect } from 'react-redux';
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import withStyles from '@material-ui/core/styles/withStyles';
import { createStructuredSelector } from 'reselect';

import ImageComponent from 'components/ImageComponent/index';
import SegmentImageComponent from 'components/ImageComponent/Segment';
import SharedImageComponent from 'components/ImageComponent/Shared';
import InternalImageComponent from 'components/ImageComponent/Internal';

import { InfiniteLoader } from 'react-virtualized/dist/es/InfiniteLoader';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import { List } from 'react-virtualized/dist/es/List';
import { WindowScroller } from 'react-virtualized/dist/es/WindowScroller';

// import { InfiniteLoader, AutoSizer, List, WindowScroller } from 'react-virtualized';

// App
import { gif as gifApi, likeMove as likeMoveApi } from 'appStore/index';
import { makeSelectBase as appMakeSelectBase } from 'appStore/app';

const MOBILE_BREAKPOINT = 420;

const breakpoints = {
  isMobile: width => width < 420,

  getColumnWidth: width => {
    switch (width) {
      case width <= 360: {
        return 360;
      }
      default: {
        return 400;
      }
    }
  },
};

class MasonryComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      listHeight: 300,
      listRowHeight: 50,
      overscanRowCount: 10,
      rowCount: props.list.length,
      scrollToIndex: undefined,
      showScrollingPlaceholder: false,
      useDynamicRowHeight: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.list) !== JSON.stringify(prevProps.list)) {
      // this._reset();
      scroll.scrollMore(1); // Needed to fix Masonry rendering bug
    }
  }

  componentDidMount() {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  _getDatum(index) {
    const { list } = this.props;
    return list[index % list.length];
  }

  _noRowsRenderer() {
    return 'No rows';
  }

  _rowRenderer = ({ index, isScrolling, key, style }) => {
    return this.renderSegmentMobile(this._getDatum(index), style);
  };

  _getRowHeight = ({ index }) => {
    return this._getDatum(index).height + 150;
  };

  _loadMoreRows = ({ startIndex, stopIndex }) => {
    const { loadMore, list } = this.props;
    if (list.length - stopIndex < 10) loadMore();
  };

  renderSegmentMobile(segment, style = {}) {
    const { reset, type } = this.props;

    if (type === 'internal') {
      return (
        <InternalImageComponent
          segment={segment}
          style={{ width: '100%', marginBottom: 10 }}
          reset={reset}
        />
      );
    } else if (type === 'segment') {
      return (
        <div key={segment.id} style={{ ...style, width: '100%' }}>
          <SegmentImageComponent
            segment={segment}
            style={{ width: '100%', marginBottom: 10 }}
            reset={reset}
            key={segment.id}
          />
        </div>
      );
    } else if (type === 'shared') {
      return (
        <div key={segment.id} style={{ ...style, width: '100%' }}>
          <SharedImageComponent
            segment={segment}
            style={{ width: '100%', marginBottom: 10 }}
            reset={reset}
            key={segment.id}
          />
        </div>
      );
    } else {
      return (
        <div key={segment.id} style={{ ...style, width: '100%' }}>
          <ImageComponent
            gif={segment}
            {...style}
            style={{ width: '100%', marginBottom: 10 }}
            reset={reset}
            key={segment.id}
          />
        </div>
      );
    }
  }

  render() {
    const { list, classes, gifCount, likedCount } = this.props;

    return (
      <div className={classes.mobileContent}>
        <InfiniteLoader
          isRowLoaded={({ index }) => !!list[index]}
          loadMoreRows={this._loadMoreRows}
          rowCount={gifCount || likedCount}
          threshold={10}
        >
          {({ onRowsRendered, registerChild }) => {
            return (
              <div>
                <WindowScroller>
                  {({ height, isScrolling, onChildScroll, scrollTop }) => {
                    return (
                      <div>
                        <AutoSizer disableHeight>
                          {({ width }) => (
                            <List
                              ref={registerChild}
                              autoHeight
                              width={width}
                              height={height}
                              rowHeight={this._getRowHeight}
                              isScrolling={isScrolling}
                              onScroll={onChildScroll}
                              rowCount={gifCount || likedCount}
                              rowRenderer={this._rowRenderer}
                              overscanRowCount={10}
                              scrollTop={scrollTop}
                              onRowsRendered={onRowsRendered}
                            />
                          )}
                        </AutoSizer>
                      </div>
                    );
                  }}
                </WindowScroller>
              </div>
            );
          }}
        </InfiniteLoader>
      </div>
    );
  }

  renderImageComponent(style, index) {
    const { list, reset, type } = this.props;
    const { columnWidth } = this.state;
    const datum = list[index % list.length];
    if (type === 'segment') {
      return (
        <SegmentImageComponent
          {...style}
          segment={datum}
          style={{ ...style, width: columnWidth }}
          reset={reset}
        />
      );
    } else if (type === 'shared') {
      return (
        <SharedImageComponent
          {...style}
          segment={datum}
          style={{ ...style, width: columnWidth }}
          reset={reset}
        />
      );
    } else {
      return (
        <ImageComponent
          {...style}
          gif={datum}
          style={{ ...style, width: columnWidth }}
          reset={reset}
        />
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...createStructuredSelector({
      dimensions: appMakeSelectBase('dimensions'),
      gifCount: gifApi.makeSelectBase('count'),
      likedCount: likeMoveApi.makeSelectBase('count'),
    })(state, props),
  };
};

const styles = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: '0px 10px',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 30px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0px 40px',
    },
  },

  mobileContent: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
});

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles, { withTheme: true })(MasonryComponent));
