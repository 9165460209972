import ReduxAPI from './api';
import ReduxVideo from './video';
import ReduxBoard from './board';
import ReduxYoutube from './youtube';
import appReducer from './app';

import { reducer as notificationReducer } from './notification';

export const userAuth = new ReduxAPI('userAuth', '/api/v1/rest-auth/');
export const dance = new ReduxAPI('dance', '/api/v1/dance/');
export const label = new ReduxAPI('dance', '/api/v1/label/');
export const classes = new ReduxAPI('classes', '/api/v1/class/');
export const gif = new ReduxAPI('gif', '/api/v1/resources/gif/');
export const tag = new ReduxAPI('tag', '/api/v1/core/tag/');
export const user = new ReduxAPI('user', '/api/v1/user/user/');
export const board = new ReduxBoard('board', '/api/v1/board/board/');
export const video = new ReduxVideo('video', '/api/v1/source/video/');
export const youtube = new ReduxYoutube('youtube', '/api/v1/source/youtube/');
export const segment = new ReduxAPI('segment', '/api/v1/source/segment/');
export const library = new ReduxAPI('library', '/api/v1/board/library/');
export const team = new ReduxAPI('team', '/api/v1/team/team/');
export const teammembership = new ReduxAPI('teammembership', '/api/v1/team/membership/', {
  isNotify: true,
  name: 'Team Membership',
});

export const likeMove = new ReduxAPI('likeMove', '/api/v1/favourite/like-move/');

export const aiLabel = new ReduxAPI('aiLabel', '/api/v1/ai/label/');
export const aiObjTrack = new ReduxAPI('aiObjTrack', '/api/v1/ai/object_tracking/', {
  isNotify: true,
  name: 'Tracking',
});

export default {
  userAuth: userAuth.reducer,
  dance: dance.reducer,
  classes: classes.reducer,
  gif: gif.reducer,
  tag: tag.reducer,
  user: user.reducer,
  board: board.reducer,
  app: appReducer,
  video: video.reducer,
  youtube: youtube.reducer,
  segment: segment.reducer,
  library: library.reducer,
  team: team.reducer,
  teammembership: teammembership.reducer,
  aiLabel: aiLabel.reducer,
  aiObjTrack: aiObjTrack.reducer,
  likeMove: likeMove.reducer,
  label: label.reducer,
  notification: notificationReducer,
};
