// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// App
// import MethodComponent from './Method';
import EmailComponent from './Email';
import { buttonBlack } from 'styles/classnames';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: 30,
    marginBottom: 30,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      marginTop: 100,
      marginBottom: 40,
      width: 280,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

interface MainProps {
  isLogin: boolean;
  onClose?: VoidFunction;
  onSuccess: VoidFunction;
  setIsEmail: (value: boolean) => void;
  setIsLogin: (value: boolean) => void;
}

export default function Main({
  // onClose,
  onSuccess,
  // isEmail,
  setIsEmail,
  setIsLogin,
  isLogin,
}: MainProps) {
  const classes = useStyles();

  let headerTitle = 'Login';
  let otherContent = <span />;
  let subtitle = '';

  if (isLogin) {
    headerTitle = 'Log in to Dancelogue';
    subtitle = 'Save the moves you like.';
    otherContent = (
      <div>
        Don't have an account?{' '}
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={buttonBlack}
          onClick={() => {
            setIsEmail(false);
            setIsLogin(!isLogin);
          }}
        >
          Sign up
        </Button>
      </div>
    );
  } else {
    headerTitle = 'Sign up for Dancelogue.';
    subtitle = 'Save the moves you like.';
    otherContent = (
      <div>
        Already have an account?{' '}
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={buttonBlack}
          onClick={() => {
            setIsEmail(false);
            setIsLogin(!isLogin);
          }}
        >
          Log in
        </Button>
      </div>
    );
  }

  return (
    <form className={classes.form} noValidate>
      <Grid justify="center">
        <Grid xs={12} sm={12} md={12}>
          <Typography variant="h5" gutterBottom align="center">
            {headerTitle}
          </Typography>

          <Typography variant="body2" gutterBottom align="center">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>

      {/* {!isEmail ? (
        <MethodComponent
          onClose={onClose}
          onSuccess={onSuccess}
          setIsEmail={setIsEmail}
          isLogin={isLogin}
        />
      ) : (
        <EmailComponent onClose={onClose} onSuccess={onSuccess} isLogin={isLogin} />
      )} */}

      <EmailComponent onSuccess={onSuccess} isLogin={isLogin} />

      <Grid justify="center" style={{ marginTop: 30 }}>
        <Grid xs={12} sm={12} md={12}>
          {otherContent}
        </Grid>
      </Grid>
    </form>
  );
}
