import { FC, useState, createContext, useEffect } from 'react';

// App
import { UserAuthType, BoardType } from 'types/api';
import Axios from 'utils/axios';
import {
  userAuth as userAuthApi,
  gif as gifApi,
  board as boardApi,
  likeMove as likeMoveApi,
} from 'store/index';
import { useDispatch, useSelector } from 'hooks';

export type IState = {
  user?: UserAuthType;
  userInitialized: boolean;
  error: string;
  isReset: boolean;
  currentBoard?: BoardType;
};

export type IDispatch = {
  setError: (error: string) => void;
  logout: () => void;
  resetView: (value: boolean) => void;
  getGifRequest: VoidFunction;
  getCurrentBoard: VoidFunction;
};

type ProviderProps = {
  children: React.ReactNode;
};

const InitialState: Partial<IState> = {};

export type GlobalContextProps = IState & IDispatch;
export const GlobalContext = createContext<GlobalContextProps>(InitialState as GlobalContextProps);

const GlobalProvider: FC<ProviderProps> = ({ children }) => {
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const userInitialized: boolean = useSelector(
    (state: any) => state[userAuthApi.APP_NAME].initialized,
  );
  const currentBoard: BoardType = useSelector((state: any) => state[boardApi.APP_NAME].detail);

  const [error, setError] = useState('');
  const [isReset, resetView] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userAuthApi.getDetailRequest('user'));
  }, []);

  useEffect(() => {
    if (user?.id && !currentBoard?.id) {
      dispatch(boardApi.getDetailRequest('current_board'));
      dispatch(boardApi.getRequest());
    }
  }, [user?.id]);

  const logout = () => {
    Axios({
      url: `/api/v1/rest-auth/logout/`,
      method: 'POST',
    })
      .then(() => {
        dispatch(userAuthApi.resetDetail());
        dispatch(boardApi.resetDetail());
        dispatch(gifApi.resetDetail());
        dispatch(likeMoveApi.resetDetail());

        window.localStorage.clear();
      })
      .catch(() => {
        alert("Something went wrong, can't logout");
      });
  };

  const value: GlobalContextProps = {
    user,
    userInitialized,
    currentBoard,
    error,
    setError,
    logout,
    isReset,
    resetView,
    getGifRequest: () => {
      dispatch(gifApi.getRequest()).then(() => {
        resetView(true);
        setTimeout(() => resetView(false), 500);
      });
    },
    getCurrentBoard: () => {
      dispatch(boardApi.getDetailRequest('current_board'));
    },
  };

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export default GlobalProvider;
