// logging stuff

const ENABLE_LOG = false;

export default {
  info: (...args: any) => {
    ENABLE_LOG && console.log(...args);
  },
  error: (...args: any) => {
    ENABLE_LOG && console.log(...args);
  },
  actionError: (_error: any, params: any) => {
    ENABLE_LOG && console.log('actionError: ', params);
    try {
      // analytics.logEventError(params);
      // Sentry.captureException(error, { extra: params });
    } catch (err) {}
  },
  warn: (...args: any) => {
    ENABLE_LOG && console.log(...args);
  },
  debug: (...args: any) => {
    ENABLE_LOG && console.log(...args);
  },

  compression: (params: any) => {
    ENABLE_LOG && console.log(params);
    // Sentry.captureMessage('Compression Statistics', { extra: params });
  },

  exception(error: any, params: any = {}) {
    ENABLE_LOG && console.log(error, params);
    // try { Sentry.captureException(error, { extra: params }); } catch (err) {};
  },
};
