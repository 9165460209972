import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { createStructuredSelector } from 'reselect';

// App
import { makeSelectBase as appMakeSelectBase } from 'appStore/app';
import { breakpoints } from 'appUtils/core';
import MobileComponent from './Mobile';
import DesktopComponent from './Desktop';

class MasonryComponent extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.forceUpdate();
  };

  render() {
    const { dimensions } = this.props;
    if (breakpoints.isMobile(dimensions && dimensions.width))
      return <MobileComponent {...this.props} />;
    return <DesktopComponent {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...createStructuredSelector({
      dimensions: appMakeSelectBase('dimensions'),
    })(state, props),
  };
};

const styles = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: '0px 10px',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 30px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0px 40px',
    },
  },

  mobileContent: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
});

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles, { withTheme: true })(MasonryComponent));
